<template>
  <div class="px-3">
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else class="text-normal">
      <!-- <pre>{{ questionList }}</pre> -->
      <b-row>
        <b-col sm="4" class="pl-0 pr-0 pr-md-1">
          <div class="header-secondary title-tabs header-title">
            <span>Questions</span>
            <b-button class="add-btn" @click="addQuestion">
              <b-icon icon="plus-square" class="mr-2"></b-icon
              ><span>Create New</span></b-button
            >
          </div>
        </b-col>
        <b-col sm="8" class="pr-0 pl-0 pl-md-1">
          <div
            class="header-secondary title-tabs header-title d-none d-sm-block"
          >
            <span>Detail</span>
          </div>
        </b-col>
      </b-row>

      <b-row class="bg-grey">
        <b-col sm="4" class="pl-0 pr-0 pr-md-1">
          <div class="p-2 question-panel shadow-sm overflow-auto">
            <draggable :list="questionList" @end="changeDrag">
              <div
                :class="`${
                  questionActive == index ? 'active ' : ''
                }mt-2 p-3 question-card pointer`"
                v-for="(ques, index) in questionList"
                :key="index"
                @click="questionActive = index"
              >
                <div class="d-flex align-items-center" style="flex-flow: wrap">
                  <span class="mr-2 text-black font-weight-bold"
                    >Question {{ index + 1 }}:</span
                  >
                  <div class="mr-2 type-box">
                    {{ questionTypeText(ques.field_type_id) }}
                  </div>
                  <span style="word-break: break-all">{{ ques.name }}</span>
                </div>
                <div class="mt-1">
                  <p class="mb-1 text-light-grey text-truncate">
                    {{ questionDetailText(ques) }}
                  </p>
                </div>
              </div>
            </draggable>
          </div>
        </b-col>
        <b-col sm="8" class="pr-0 pl-1">
          <div class="header-secondary title-tabs d-block d-sm-none">
            <span>Detail</span>
          </div>
          <div class="pt-2 bg-white text-normal shadow-sm">
            <div v-if="questionList[questionActive].field_type_id == 0">
              <b-row
                class="px-3"
                v-for="(profile, index) in questionList[questionActive].profile"
                :key="index"
              >
                <b-col sm="2">
                  <div v-if="index == 0" class="text-center">Sort Order</div>
                  <div class="content-between mt-3">
                    <font-awesome-icon
                      icon="chevron-circle-up"
                      @click="switchProfileIndex(profile, index, false)"
                      :class="`icon-size ${
                        index == 0 ? 'disabled' : 'pointer'
                      }`"
                    />
                    {{ index + 1 }}
                    <font-awesome-icon
                      icon="chevron-circle-down"
                      @click="switchProfileIndex(profile, index, true)"
                      :class="`icon-size ${
                        index == questionList[questionActive].profile.length - 1
                          ? 'disabled'
                          : 'pointer'
                      }`"
                    />
                  </div>
                </b-col>
                <b-col sm="1">
                  <div v-if="index == 0">Required</div>

                  <b-form-checkbox
                    class="disableCheckbox mt-3 mb-1"
                    :id="`checkbox-profile-${index}`"
                    :name="`checkbox-profile-${index}`"
                    v-model="profile.required"
                    @input="handleCheckRequired($event, profile)"
                    :disabled="disabledField(profile)"
                  >
                  </b-form-checkbox
                ></b-col>
                <b-col sm="4">
                  <div v-if="index == 0" class="text-center">Field Name</div>
                  <InputText
                    v-if="!isLoading"
                    textFloat=""
                    :value="profile.profile_name"
                    disabled
                    :placeholder="profile.profile_name"
                    class="margin-input"
                    type="text"
                  />
                </b-col>
                <b-col sm="4">
                  <div v-if="index == 0" class="text-center">Description</div>
                  <InputText
                    v-if="!isLoading"
                    textFloat=""
                    v-model="profile.name"
                    :placeholder="profile.name"
                    class="margin-input"
                    type="text"
                  />
                </b-col>

                <b-col sm="1">
                  <div v-if="index == 0">Display</div>
                  <b-form-checkbox
                    class="disableCheckbox mt-3 mb-1"
                    :id="`checkbox-profile-is-show-${index}`"
                    :name="`checkbox-profile-is-show-${index}`"
                    v-model="profile.is_show"
                    :value="1"
                    :unchecked-value="0"
                    :disabled="profile.required || disabledField(profile)"
                  >
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </div>
            <div v-else class="p-3">
              <div
                class="content-between"
                v-if="questionList[questionActive].field_profile_type_id != 20"
              >
                <div>
                  <b-form-checkbox
                    v-model="questionList[questionActive].is_show"
                    :checked="questionList[questionActive].is_show"
                    :value="1"
                    :unchecked-value="0"
                    @change="
                      $event == 0
                        ? (questionList[questionActive].required = false)
                        : undefined
                    "
                    >Show on Register Form</b-form-checkbox
                  >
                </div>
                <div class="d-flex justify-content-end align-items-center">
                  <label class="mb-0 mr-2">Required</label>
                  <b-form-checkbox
                    class="mb-1 mr-2"
                    size="lg"
                    v-model="questionList[questionActive].required"
                    :disabled="
                      typeHidden(questionList[questionActive].field_type_id) ||
                      !questionList[questionActive].is_show
                    "
                    switch
                  >
                  </b-form-checkbox>
                  <b-icon
                    size="lg"
                    icon="trash-fill"
                    class="icon-size pointer"
                    @click="deleteQuestion"
                  />
                </div>
              </div>
              <div class="mt-3 w-25">
                <b-form-select
                  v-model="questionList[questionActive].field_type_id"
                  :options="fieldTypes"
                  @change="changeQeustionType"
                  value-field="id"
                  text-field="name"
                  :disabled="questionList[questionActive].is_required"
                ></b-form-select>
              </div>
              <div class="mt-3">
                <div
                  v-if="
                    !(
                      questionList[questionActive].field_type_id == 10 ||
                      questionList[questionActive].field_type_id == 11 ||
                      questionList[questionActive].field_type_id == 13 ||
                      questionList[questionActive].field_type_id == 14
                    )
                  "
                >
                  <InputText
                    class="text-float-normal w-50"
                    textFloat="Validate Text"
                    v-model="questionList[questionActive].validate_text"
                    :placeholder="questionList[questionActive].validate_text"
                    :disabled="
                      questionList[questionActive].field_type_id == 10 ||
                      questionList[questionActive].field_type_id == 11 ||
                      questionList[questionActive].field_type_id == 13 ||
                      questionList[questionActive].field_type_id == 14
                    "
                    type="text"
                  />
                </div>
                <div
                  v-if="
                    !(
                      questionList[questionActive].field_type_id == 9 ||
                      questionList[questionActive].field_type_id == 13 ||
                      questionList[questionActive].field_type_id == 14
                    )
                  "
                >
                  <InputText
                    isRequired
                    :class="[
                      'text-float-normal question-name-input margin-input-5',
                      isSubmit && !questionList[questionActive].name
                        ? 'error'
                        : '',
                    ]"
                    textFloat="Question"
                    v-model="questionList[questionActive].name"
                    :disabled="
                      questionList[questionActive].field_type_id == 9 ||
                      questionList[questionActive].field_type_id == 13 ||
                      questionList[questionActive].field_type_id == 14
                    "
                    :placeholder="questionList[questionActive].name"
                    type="text"
                  />
                  <div v-if="isSubmit && !questionList[questionActive].name">
                    <span class="text-error">Please input text.</span>
                  </div>
                </div>
              </div>
              <div class="my-3">
                <FieldTextDetail
                  v-if="questionList[questionActive].field_type_id == 1"
                  :questionList="questionList"
                  :questionActive="questionActive"
                />
                <FieldChoiceDetail
                  v-if="
                    questionList[questionActive].field_type_id >= 2 &&
                    questionList[questionActive].field_type_id <= 4
                  "
                  :questionList="questionList"
                  :questionActive="questionActive"
                />
                <DateInput
                  class="disabled"
                  :disabled="true"
                  v-if="questionList[questionActive].field_type_id == 5"
                />
                <ModalSelectBranch
                  v-if="
                    questionList[questionActive].branchList &&
                    questionList[questionActive].branchList.length > 0 &&
                    questionList[questionActive].field_type_id == 6
                  "
                  :list="questionList[questionActive].branchList"
                  @selectBranch="selectBranch"
                  :field="questionList[questionActive]"
                  text="Select Branch"
                  :id="id"
                  :BranchSelectList="
                    questionList[this.questionActive].select_branch
                  "
                />
                <Date
                  v-if="questionList[questionActive].field_type_id == 7"
                  class=""
                />
                <!-- <UploadImage
                  v-if="questionList[questionActive].field_type_id == 8"
                  :indexData="0"
                  @updateImageList="updateImageList"
                  :dataList="imageListShow"
                  @deleteImagesList="deleteImgList"
                /> -->
                <InputTextArea
                  v-if="questionList[questionActive].field_type_id == 12"
                  textFloat=""
                  type="text"
                  class="mt-3 custom-input"
                  placeholder=""
                  rows="3"
                />
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import Date from "@/components/booking/preview/Date";
import DateInput from "@/components/booking/preview/DateInput";
import ModalSelectBranch from "@/components/ModalSelectBranch";
import OtherLoading from "@/components/loading/OtherLoading";
import FieldChoiceDetail from "./FieldChoiceDetail.vue";
import FieldTextDetail from "./FieldTextDetail.vue";
import UploadImage from "../../../views/pages/form/report/component/UploadImage.vue";
import InputText from "@/components/inputs/InputText";
import InputTextArea from "@/components/inputs/InputTextArea";
export default {
  components: {
    draggable,
    Date,
    DateInput,
    ModalSelectBranch,
    OtherLoading,
    FieldChoiceDetail,
    FieldTextDetail,
    UploadImage,
    InputText,
    InputTextArea,
  },
  props: {
    imageListShow: {
      required: true,
      type: Array,
    },
    deleteImageList: {
      required: true,
      type: Array,
    },
    fields: {
      required: true,
      type: Array,
    },
    fieldTypes: {
      required: true,
      type: Array,
    },
    profileType: {
      required: true,
      type: Array,
    },
    id: {
      required: true,
      type: String,
    },
    form: {
      required: true,
    },
    input_id: {
      required: false,
    },
  },
  data() {
    return {
      isLoading: true,
      isBusy: false,
      isSubmit: false,
      questionList: [],
      questionActive: 0,
      questionProfileIndex: null,
      showProfilePanel: true,
      branchList: [],
      draggable: {
        drag: true,
      },
      draggableOption: {
        ghostClass: "sortable-ghost",
        chosenClass: "sortable-chosen",
        dragClass: "sortable-drag",
        animation: 200,
      },
    };
  },
  computed: {
    indexCustomerDetail() {
      return this.questionList.findIndex((q) => q.field_type_id == 0);
    },
  },
  watch: {
    indexCustomerDetail: {
      handler(val) {
        this.questionProfileIndex = this.indexCustomerDetail;
      },
    },
  },
  async created() {
    let number = 0;

    const FindProfile = this.questionList.findIndex(
      (el) => el.field_type_id == 0
    );
    this.questionList = [];

    if (this.fields.length == 1 && FindProfile != -1) {
      this.profileType.forEach((p, index) => {
        if (this.questionList[FindProfile]) {
          if (
            this.questionList[FindProfile].profile.findIndex(
              (q) => q.field_profile_type_id == p.field_profile_type_id
            ) == -1 &&
            p.field_type_id != 9
          ) {
            p.avairable = false;
            p.img = "";
            p.is_show = 0;
            p.select_branch = [];
            this.questionList[FindProfile].profile.push(p);
          }
        }
      });
    } else {
      for (const field of this.fields) {
        if (field.field_type_id != 9) {
          if (
            field.field_type_id != 10 &&
            field.field_type_id != 11 &&
            field.field_type_id != 13 &&
            field.field_type_id != 14
          ) {
            field.validate_text = field.validate_text
              ? field.validate_text
              : this.defaultValidateText(field.field_type_id);
          } else {
            field.validate_text = "";
          }
          if (
            field.field_profile_type_id &&
            field.field_profile_type_id !== 0 &&
            field.field_profile_type_id != 20
          ) {
            this.questionProfileIndex =
              this.questionProfileIndex == null
                ? number
                : this.questionProfileIndex;
            field.avairable = true;
            field.number = number;
            if (field.required) {
              field.is_show = 1;
            }
            if (!this.questionList[this.questionProfileIndex]) {
              this.questionList[this.questionProfileIndex] = {
                field_type_id: 0,
                name: "",
                profile: [field],
              };
            } else {
              this.questionList[this.questionProfileIndex].profile.push(field);
            }
          } else {
            field.number = number;
            this.questionList.push(field);
          }

          if (field.field_type_id === 6) {
            this.isLoading = true;
            await this.getBranchList(field, field.branch_group_id);
          }
        }
        number += 1;

        if (number == this.fields.length - 1) {
        }
      }
    }
    this.profileType.forEach((p, index) => {
      if (this.questionList[this.questionProfileIndex]) {
        if (
          !this.questionList[this.questionProfileIndex].profile.find(
            (q) => q.field_profile_type_id == p.field_profile_type_id
          )
        ) {
          p.avairable = false;
          p.img = "";
          p.is_show = 0;
          p.select_branch = [];
          this.questionList[this.questionProfileIndex].profile.push(p);
        }
      }
    });

    let result = this.fields.some((e) => e.field_type_id === 6);
    // if (this.id == 0) {
    this.addQuestionDatetime();
    // }
    // }
    if (!result) {
      this.isLoading = false;
    }
  },
  methods: {
    handleCheckRequired(val, items) {
      if (val) {
        this.$nextTick(() => (items.is_show = 1));
      }
    },
    disabledField(items) {
      if (
        items.field_profile_type_id == 3 ||
        items.field_profile_type_id == 5 ||
        items.field_profile_type_id == 10
      ) {
        return true;
      }
      if (items.is_profile_required) {
        return true;
      }
      if (this.isRegister) {
        return true;
      }
      return false;
    },

    changeDrag(value) {
      this.questionActive = value.newIndex;
    },
    addQuestionDatetime() {
      let hasField = this.form.fields.find(
        (el) => el.field_profile_type_id == 20
      );
      if (!hasField) {
        this.questionList.splice(1, 0, {
          field_type_id: 5,
          required: true,
          img: "",
          id: this.input_id,
          field_id: this.input_id,
          name: hasField ? hasField.name : "ระบุเวลา",
          number: hasField ? hasField.number : 1,
          sort_order: hasField ? hasField.sort_order : 1,
          is_show: 1,
          is_profile_required: true,
          field_profile_type_id: 20,
          select_branch: [],
          field_choices: [],
          field_conditions: [],
          validate_text: this.defaultValidateText(1),
          is_required: true,
        });
        this.questionList.sort((a, b) => a.sort_order - b.sort_order);
      }
    },
    addQuestion() {
      this.questionList.push({
        field_type_id: 1,
        required: false,
        img: "",
        name: "",
        is_show: 1,
        field_profile_type_id: 0,
        select_branch: [],
        field_choices: [],
        field_conditions: [
          { field_condition_type_id: 1, value: "text" },
          { field_condition_type_id: 2, value: "" },
          { field_condition_type_id: 3, value: "" },
          { field_condition_type_id: 4, value: "" },
        ],
        validate_text: this.defaultValidateText(1),
      });
      this.questionActive = this.questionList.length - 1;
    },
    async changeQeustionType(value) {
      if (value == 1) {
        this.questionList[this.questionActive].field_conditions = [
          { field_condition_type_id: 1, value: "text" },
          { field_condition_type_id: 2, value: "" },
          { field_condition_type_id: 3, value: "" },
          { field_condition_type_id: 4, value: "" },
        ];
        this.questionList[this.questionActive].field_choices = [];
      } else if (value >= 2 && value <= 4) {
        this.questionList[this.questionActive].field_conditions = [];
        this.questionList[this.questionActive].field_choices = [
          { name: "choice 1", sort_order: 0, isDefault: 0 },
        ];
      } else if (value == 5 || value == 7) {
        this.questionList[this.questionActive].field_conditions = [];
        this.questionList[this.questionActive].field_choices = [{ name: "" }];
      } else if (value == 6) {
        this.questionList[this.questionActive].field_choices = [];
        this.questionList[this.questionActive].field_conditions = [];
        let idBranch = 0;
        if (this.questionList[this.questionActive].branch_group_id) {
          idBranch = this.questionList[this.questionActive].branch_group_id;
        }
        this.isLoading = true;
        await this.getBranchList(
          this.questionList[this.questionActive],
          idBranch
        );
      } else {
        this.questionList[this.questionActive].field_conditions = [];
        this.questionList[this.questionActive].field_choices = [];
      }
      if (value != 6) {
        delete this.questionList[this.questionActive].branchList;
      }
      if (this.typeHidden(value)) {
        this.questionList[this.questionActive].required = false;
        this.questionList[this.questionActive].name = "";
      }
      this.questionList[this.questionActive].validate_text =
        this.defaultValidateText(value);
    },
    deleteQuestion() {
      this.questionList.splice(this.questionActive, 1);
      this.questionActive =
        this.questionActive > 0 ? this.questionActive - 1 : 0;
    },
    sortArray(index) {
      this.questionList[this.questionProfileIndex].profile = this.questionList[
        this.questionProfileIndex
      ].profile.map((el, i) => (el.sort_order = i + 1));
    },
    async switchProfileIndex(items, index, val) {
      if (index == 0 && !val) {
        return;
      }
      this.questionList[this.questionProfileIndex].profile.splice(index, 1);
      index = val ? index + 1 : index - 1;

      this.questionList[this.questionProfileIndex].profile.splice(
        index,
        0,
        items
      );
      this.$nextTick(() => {
        this.questionList = [...this.questionList];
        // this.sortArray();
      });
    },
    questionTypeText(type) {
      if (type == 0) {
        return "Customer Detail";
      } else {
        let index = this.fieldTypes.findIndex((field) => field.id == type);
        return this.fieldTypes[index].name;
      }
    },
    questionProfileTypeText(type) {
      return type == 1
        ? "เลขบัตรประชาชน"
        : type == 2
        ? "คำนำหน้า"
        : type == 3
        ? "ชื่อ"
        : type == 4
        ? "Name"
        : type == 5
        ? "นามสกุล"
        : type == 6
        ? "Surname"
        : type == 7
        ? "ชื่อเล่น"
        : type == 8
        ? "Nickname"
        : type == 9
        ? "วันเกิด"
        : type == 10
        ? "เบอร์โทรศัพท์"
        : type == 11
        ? "อีเมล์"
        : type == 12
        ? "บ้านเลขที่"
        : type == 13
        ? "อาคาร"
        : type == 14
        ? "ซอย"
        : type == 15
        ? "ถนน"
        : type == 16
        ? "แขวง"
        : type == 17
        ? "เขต"
        : type == 18
        ? "จังหวัด"
        : type == 19
        ? "รหัสไปรษณีย์"
        : "";
    },
    questionDetailText(item) {
      var text = "";
      if (item.field_type_id == 0) {
        let popBookingDate = item.profile.filter(
          (el) => el.field_profile_type_id != "20"
        );

        text = popBookingDate
          .filter((f) => {
            return f.avairable;
          })
          .map((p) => {
            return this.questionProfileTypeText(p.field_profile_type_id);
          })
          .join(", ");
      } else if (
        item.field_type_id == 1 ||
        item.field_type_id == 10 ||
        item.field_type_id == 11 ||
        item.field_type_id == 12
      ) {
        text = item.name;
      } else if (
        item.field_type_id == 2 ||
        item.field_type_id == 3 ||
        item.field_type_id == 4
      ) {
        text = item.field_choices
          .map((c) => {
            return c.name;
          })
          .join(", ");
      } else if (item.field_type_id == 5) {
        text = "Date/Time Field";
      } else if (item.field_type_id == 6) {
        let count = 0;
        if (item.id) {
          item.branchList.forEach((bl) => {
            bl.branch.forEach((b) => {
              count += b.is_check ? 1 : 0;
            });
          });
        } else {
          count = item.select_branch.length;
        }
        text = `${count} Choices`;
      } else if (item.field_type_id == 7) {
        text = "Date Field";
      } else if (item.field_type_id == 8) {
        text = "Image";
      }
      return text;
    },
    typeHidden(type) {
      if (type == 9 || type == 10 || type == 11 || type == 13 || type == 14) {
        return true;
      } else {
        return false;
      }
    },
    defaultValidateText(type) {
      if (type >= 2 && type <= 4) {
        return "Please select choice.";
      } else if (type == 5 || type == 7) {
        return "Please enter date.";
      } else if (type == 6) {
        return "Please select branch.";
      } else if (type == 8) {
        return "Please input image.";
      } else if (type == 10 || type == 11 || type == 13 || type == 14) {
        return "";
      } else {
        return "Please input text.";
      }
    },
    selectBranch(value) {
      this.questionList[this.questionActive].select_branch = value;
      this.$forceUpdate();
    },
    async getBranchList(field, id) {
      await this.$store.dispatch("getBranch", parseInt(id));
      field.branchList = this.$store.state.coupon.branchList.detail;
      this.branchList = this.$store.state.coupon.branchList.detail;
      this.isLoading = false;
    },
    updateImageList(list) {
      this.imageListShow = list.value;
    },
    deleteImgList(id) {
      this.deleteImageList.push(id);
    },
  },
};
</script>

<style lang="scss" scoped>
.text-normal {
  color: #5b5b5b;
}
.text-light-grey {
  color: #9a9a9a;
}
::v-deep .text-float-normal .input-custom {
  label {
    color: #5b5b5b;
    font-size: 16px;
    font-weight: 100;
  }
}
.header-title {
  height: 100%;
  padding: 8px 16px;
  margin-top: 1px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.add-btn {
  color: white;
  background-color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border: none !important;
}
.add-btn:hover {
  background-color: var(--primary-color);
}
.question-panel {
  max-height: 50vh;
  background-color: white;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 10px;
}

.question-card {
  box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.24);

  border-left: 5px solid transparent;
  background-color: white;
  border-radius: 4px;
  text-overflow: ellipsis;
}
.question-card.active {
  border-left: 5px solid var(--primary-color);
}
.type-box {
  padding: 2px 8px;
  color: var(--primary-color);
  background-color: var(--theme-secondary-color);
  border-radius: 6px;
}
.icon-size {
  font-size: 20px;
  color: #575757;
}
.icon-size.disabled {
  color: #bababa;
}
#footer {
  position: fixed;
  bottom: 0;
  left: 256px;
  right: 0;
  height: 10%;
  padding: 15px;
  background: #fff;
}
.margin-input {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}
.margin-input-5 {
  margin-bottom: 5px !important;
}
::v-deep .question-name-input.error {
  input {
    border: 1px solid red;
  }
}
.disabled {
  ::v-deep .input-container {
    background: rgba(239, 239, 239, 0.3);
    cursor: default;
    // background-color: -internal-light-dark(
    //   rgba(239, 239, 239, 0.3),
    //   rgba(59, 59, 59, 0.3)
    // );
  }
}
</style>
